<template>
    <div class="page-container">
        <div class="search-container">
            <search-v1
                    class="search"
                    v-model="keyword"
                    @search="search"
                    @clear="search"
                    placeholder="请输入要搜索的手机号码"
            ></search-v1>
        </div>
        <div class="user-list">
            <div :class="[searchUserId == user.user_id ? 'user-info-active' : '']" class="user-info"
                 v-for="(user,index) in userList" :key="'users-'+index">
                <div class="infos" @click="chooseUser(user.user_id)">
                    <div class="avatar">
                        <img :src="user.avatarUrl"/>
                    </div>
                    <div class="info">
                        <div class="nickName">{{user.nickName}}</div>
                        <div class="mobile">{{user.mobile ? user.mobile : '未设置手机号码'}}</div>
                    </div>
                </div>
                <div class="line"></div>
            </div>
        </div>
        <div class="buttons">
            <div class="button" @click="setWorker">
                确定添加
            </div>
        </div>
    </div>
</template>

<script>
  import SearchV1 from '@/template/community/search/searchV1'

  export default {
    name: 'search-user',
    components: {SearchV1},
    data () {
      return {
        keyword: '',
        userList: [],
        searchUserId: 0
      }
    },
    mounted () {
      document.title = '搜索会员'
      this.keyword = this.$route.query?.keyword || ''
      if (this.keyword) {
        this.search()
      }
    },
    methods: {
      search () {
        if (this.keyword) {
          let params = {
            keyword: this.keyword
          }
          this.$api.community.points.pointSearchUser(params).then(response => {
            this.userList = response.data
            if (response.data.length == 19) {
              //如果搜索的结果正好是20条， 提示用户搜索条件可能过于简单了
              this.$toast.text('您的搜索条件可能过于简单，请尝试输入更多关键字进行检索')
            }
          })
        } else {
          this.userList = []
          this.searchUserId = 0
        }

      },
      chooseUser (user_id) {
        if (this.searchUserId == user_id) {
          this.searchUserId = 0
        } else {
          this.searchUserId = user_id
        }
      },
      setWorker () {
        if (!this.searchUserId) {
          this.$toast.text('请点击选择您要设置为员工的会员')
          return false
        }

        let params = {
          add_user_id: this.searchUserId
        }

        this.$api.community.points.pointSetWorker(params).then(response => {
          this.$toast.text(response.message)
          if (response.error == 0) {
            this.keyword = ''
            this.searchUserId = 0
            this.userList = []
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
    .page-container {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;

        .search-container {
            padding: 5px 15px;

            .search {
                height: 36px;
            }
        }

        .buttons {
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;

            .button {
                width: 275px;
                height: 45px;
                background: linear-gradient(139deg, #FAC484 0%, #F46A17 100%);
                box-shadow: 0px 1px 6px 0px rgba(244, 106, 23, 0.55);
                border-radius: 23px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .user-list {
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow-y: scroll;

            .user-info {
                display: flex;
                flex-direction: column;
                padding: 0 15px;

                .line {
                    height: 1px;
                    background: #F2F2F2;
                    margin-top: 10px;
                }

                .infos {
                    display: flex;
                    flex-direction: row;
                    padding: 5px 0;
                    margin-top: 10px;

                    .avatar {
                        width: 48px;
                        height: 48px;
                        -webkit-border-radius: 48px;
                        -moz-border-radius: 48px;
                        border-radius: 48px;
                        overflow: hidden;
                        margin-right: 10px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .info {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        padding: 5px 0;

                        .nickName {
                            flex: 1;
                            font-size: 14px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #303030;
                            display: flex;
                            align-items: center;
                        }

                        .mobile {
                            font-size: 11px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #666666;
                        }
                    }
                }

            }

            .user-info-active {
                background: rgba(0, 0, 0, 0.1);
            }
        }
    }

</style>